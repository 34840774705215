import React from "react";
import { useNavigate  } from 'react-router-dom';


const ShopNow = () => {
  const navigate = useNavigate();

const openoferts=()=>{
  navigate('/ofertas', {  });
}
  return (
    <button onClick={openoferts} className="bg-primeColor text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-black duration-300 font-bold">
      suscribete
    </button>
  );
};

export default ShopNow;
