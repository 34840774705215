import React,{useEffect,useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MapContainer, TileLayer, Marker ,Popup} from "react-leaflet";
import { FaFacebook, FaYoutube, FaWhatsapp, FaMapMarkerAlt  } from "react-icons/fa";
import { Icon } from "leaflet";
import 'leaflet/dist/leaflet.css';

const MyModal = ({ isOpen, handleClose, data }) => {
  const iconSize = [32, 32];
  const iconAnchor = [16, 32];
  const popupAnchor = [0, -32];
  const initialCoordinates = [-17.77753227268268, -63.194904327392585];
  
  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
  const [sucursal, setSucursal] = useState({});

  useEffect(() => {
    if (data && data.length > 0) {
      const suc = data[0];
      setSucursal(suc);

      if (suc && suc.latitud !== null && suc.longitud !== null) {
        setLocation({ latitude: suc.latitud, longitude: suc.longitud });
      }
    }
  }, [data]);
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          width: 530,
          height: 520,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 2,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Información de la tienda
        </Typography>
        
        {/* Mapa */}
        <div>
        <MapContainer
          center={initialCoordinates} 
          zoom={13} 
          style={{ height: '400px', width: '100%' }} 
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {location.latitude !== 0 && location.longitude !== 0 && ( // Renderizar el marcador solo si la ubicación es diferente de 0
                <Marker position={[location.latitude, location.longitude]} >
                  <Popup>
                  {sucursal && sucursal.descripcion}
                  </Popup>
                </Marker>
              )}
        </MapContainer>
        </div>
        

        
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <ul className="flex items-center gap-2">
        {sucursal?.celular && (
          <a href={`https://wa.me/591${sucursal.celular}?text=Hola`} target="_blank" rel="noreferrer">
            <li className="flex items-center gap-1">
              <div className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                <FaWhatsapp />
              </div>
              <b style={{ color: "black" }}>{sucursal.celular}</b>
            </li>
          </a>
        )}
        
      </ul>

        </Typography>
      </Box>
    </Modal>
  );
};

export default MyModal;
