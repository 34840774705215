import React,{useState} from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MyModal from "./MyModal";

const ProductInfo = ({ productInfo,tags,sucursales }) => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
      <p className="text-xl font-semibold">Bs{productInfo.price}</p>
      <p className="text-base text-gray-600">{productInfo.des}</p>
      <p className="text-sm">Sé el primero en dejar una reseña.</p>
      <p className="font-medium text-lg">
        <span className="font-normal">Categoria:</span> {productInfo.color}
      </p>
      <button
        onClick={openModal}
        className="w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont"
      >
        Informacion tienda
      </button>
      {/* <a onClick={openModal}>Informacion tienda</a> */}
      <p className="font-normal text-sm">
        <span className="text-base font-medium"> Categorias:</span> 
        <br/>
        <Stack direction="row" spacing={1}>
          {tags && tags.map((dt)=>(
              <Chip label={dt.nombre} />
          ))}
        </Stack>
      </p>

      <MyModal isOpen={modalOpen} handleClose={closeModal} data={sucursales} />
   
    </div>
  );
};
/*const MyModal = ({ isOpen, handleClose, data }) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 2,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Información de la tienda
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {data.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </Typography>
      </Box>
    </Modal>
  );
};*/
export default ProductInfo;
