import axios from "axios";

const types = {
  Success: "success",
  Info: "info",
  Warning: "warning",
  Error: "error",
};
const url = "https://rogerty93.bsite.net/api";
//const url = "https://localhost:7256/api";


const ObtenerMenbresias = async () => {
    try {
      const res = await axios.get(
        url+"/Service/getMembresias" 
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data.length > 0) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };
  const ObtenerNewProductos = async () => {
    try {
      const res = await axios.get(
        url+"/Service/getnewProductos" 
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data.length > 0) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };
  const ObtenerPromProductos = async () => {
    try {
      const res = await axios.get(
        url+"/Service/getpromocionProductos" 
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data.length > 0) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };
  const ObtenerTopProductos = async () => {
    try {
      const res = await axios.get(
        url+"/Service/gettopProductos" 
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data.length > 0) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };
  const ObtenerOfertas = async () => {
    try {
      const res = await axios.get(
        url+"/Service/getpublicidad" 
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data.length > 0) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };
  const ObtenerInforProducto = async (idpr) => {
    try {
      const res = await axios.get(
        url+"/Service/getproductodetalle?idproducto="+idpr 
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data.length > 0) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };
  const ObtenerDatainicial = async () => {
    try {
      const res = await axios.get(
        url+"/Service/getinfobuscqueda" 
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };
  const ObtenerProductos = async () => {
    try {
      const res = await axios.get(
        url+"/Service/getproductosall" 
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data.length > 0) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };
  const ObtenerBusquedaProducto = async (dataini) => {
    try {
      const res = await axios.post(
        url+"/Service/getProductoBusqueda" ,dataini
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data.length > 0) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };




const GenerarRespuesta = ({ message, type, value }) => {
    let mensaje = "";
    let tipo = types.Error;
    let valor = null;
    if (message) mensaje = message;
    if (type) tipo = type;
    if (value) valor = value;
    return {
      message: mensaje,
      type: tipo,
      value: valor,
    };
  };
  const ObtenerLoginData = async (mdata) => {
    try {
      const res = await axios.post(
        url+"/Service/loginsucursal" ,mdata
      );
      const { status, message, data } = res.data;
      if (status === 0) {
        const respuesta = GenerarRespuesta({
          message: `Hubo un error en el servidor: ${message}`,
          type: types.Warning,
          value: [],
        });
        return respuesta;
      } else {
        let tipo = types.Warning;
        if (data) {
          // eslint-disable-next-line no-unused-vars
          tipo = types.Success;
        }
        const respuesta = GenerarRespuesta({
          message: message,
          type: tipo,
          value: data,
        });
        return respuesta;
      }
    } catch (error) {
      console.error("error al obtener datos:", error);
      const respuesta = GenerarRespuesta({
        message: error,
        type: types.Error,
        value: [],
      });
      return respuesta;
    }
  };
  const GenerarParametro = (key, value) => {
    return key + "=" + value;
  };

  export {
    ObtenerMenbresias,
    ObtenerNewProductos,
    ObtenerPromProductos,
    ObtenerTopProductos,
    ObtenerOfertas,
    ObtenerInforProducto,
    ObtenerDatainicial,
    ObtenerProductos,
    ObtenerBusquedaProducto,
    ObtenerLoginData
  };
  