import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    //setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Precios" prevLocation={prevLocation} />
      <div className="pb-10">
      <PricingTable  highlightColor='#1976D2'>
          <PricingSlot   buttonText='TRY IT FREE' title='FREE' priceText='$0/month'>
              <PricingDetail> <b>15</b> projects</PricingDetail>
              <PricingDetail> <b>5 GB</b> storage</PricingDetail>
              <PricingDetail> <b>5</b> users</PricingDetail>
              <PricingDetail strikethrough> <b>Time tracking</b></PricingDetail>
          </PricingSlot>
          <PricingSlot highlighted  buttonText='SIGN UP' title='BASIC' priceText='$24/month'>
              <PricingDetail> <b>35</b> projects</PricingDetail>
              <PricingDetail> <b>15 GB</b> storage</PricingDetail>
              <PricingDetail> <b>Unlimited</b> users</PricingDetail>
              <PricingDetail> <b>Time tracking</b></PricingDetail>
          </PricingSlot>
          <PricingSlot  buttonText='SIGN UP' title='PROFESSIONAL' priceText='$99/month'>
              <PricingDetail> <b>100</b> projects</PricingDetail>
              <PricingDetail> <b>30 GB</b> storage</PricingDetail>
              <PricingDetail> <b>Unlimited</b> users</PricingDetail>
              <PricingDetail> <b>Time tracking</b></PricingDetail>
          </PricingSlot>
          <PricingSlot   buttonText='SIGN UP' title='ENTERPRISE' priceText='$200/month'>
              <PricingDetail> <b>Unlimited</b> projects</PricingDetail>
              <PricingDetail> <b>75 GB</b> storage</PricingDetail>
              <PricingDetail> <b>Unlimited</b> users</PricingDetail>
              <PricingDetail> <b>Time tracking</b></PricingDetail>
          </PricingSlot>
      </PricingTable>
       
      </div>
    </div>
  );
};

export default About;
