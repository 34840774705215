import React, { useState } from "react";
// import { FaPlus } from "react-icons/fa";
import { ImPlus } from "react-icons/im";
import NavTitle from "./NavTitle";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ListSubheader } from "@mui/material";

const Category = ({categorias}) => {
  const [expandedSubcategories, setExpandedSubcategories] = useState(new Set());

  const toggleSubcategory = (idSeccion) => {
    setExpandedSubcategories((prevSet) => {
      const newSet = new Set(prevSet);
      if (newSet.has(idSeccion)) {
        newSet.delete(idSeccion);
      } else {
        newSet.add(idSeccion);
      }
      return newSet;
    });
  };
  return (

    <div className="w-full">
       <h5> <b>Filtrar por Categoría</b></h5>
       <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
    >
      {categorias &&
        categorias.map((categoria) => (
          <React.Fragment key={categoria.idSeccion}>
            <ListItem
              button
              onClick={() => toggleSubcategory(categoria.idSeccion)}
              sx={{
                borderBottom: '1px solid #F0F0F0',
                justifyContent: 'space-between',
                gap: 4,
              }}
            >
              <ListItemText primary={categoria.nombre} />
              {categoria.seccioneshijos && categoria.seccioneshijos.length > 0 && (
                <ImPlus
                  className="text-[10px] lg:text-xs cursor-pointer text-gray-400 hover:text-primeColor duration-300"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSubcategory(categoria.idSeccion);
                  }}
                />
              )}
            </ListItem>
            <Collapse in={expandedSubcategories.has(categoria.idSeccion)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {categoria.seccioneshijos &&
                  categoria.seccioneshijos.map((subcategoria) => (
                    <ListItem key={subcategoria.idSeccion} sx={{ paddingLeft: 4 }}>
                      <ListItemText primary={subcategoria.nombre} />
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
    </List>
    </div>
    
  );
};

export default Category;
