import React from "react";
import { SplOfferData } from "../../../constants";
import { noviable } from "../../../assets/images";
const ProductsOnSale = ({productos}) => {
  return (
    <div>
      <h3 className="font-titleFont text-xl font-semibold mb-6 underline underline-offset-4 decoration-[1px]">
      Productos similares
      </h3>
      <div className="flex flex-col gap-2">
        {productos && productos.map((item) => (
          <div
            key={item.idProducto}
            className="flex items-center gap-4 border-b-[1px] border-b-gray-300 py-2"
          >
            <div>
              <img className="w-24" src={item.multimedia.length > 0 && item.multimedia[0].base64 ? `data:image/jpeg;base64, ${item.multimedia[0].base64}` : noviable} alt={""} />
            </div>
            <div className="flex flex-col gap-2 font-titleFont">
              <p className="text-base font-medium">{item.codigo}</p>
              <p className="text-sm font-semibold">Bs{item.precioNormal}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsOnSale;
