import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  imagenAmortiguador ,
  imagenFiltroAceite ,
  imagenPastillasFreno ,
  imagenBateriaAutomovil ,
  noviable,
} from "../../../assets/images/index";

const BestSellers = ({producto}) => {
  return (
    <div className="w-full pb-20">
    <Heading heading="Los mas vendidos" />
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
    {producto.map((product) => (
        <Product
          _id={product.idProducto.toString()}  // Convertir idProducto a cadena
          img={product.multimedia.length > 0 && product.multimedia[0].base64 ? `data:image/jpeg;base64, ${product.multimedia[0].base64}` : noviable}
          productName={product.codigo}
          badge={true}
          price={product.precioNormal.toFixed(2)}  // Formatear precioNormal a dos decimales
          des={product.descripcion}
        />
      ))}

      
    </div>
  </div>
  );
};

export default BestSellers;
