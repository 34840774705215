import React from "react";

const Box = (props) => {
  const { membresia, caracteristicas, btnClass, btnTitle } = props;
  const { codigo, nombre, descripcion, precio, meses, valido } = membresia;

  return (
    <div className="card mb-4 shadow-sm">
      <div className="card-header">
        <h4  className="my-0 font-weight-normal">{nombre}</h4>
        <h5 className="my-0 font-weight-normal">{descripcion}</h5>
      </div>
      <div className="card-body">
        <h1 className="card-title pricing-card-title">
          ${precio} <small className="text-muted">/ Mes</small>
        </h1>
        <ul className="list-unstyled mt-3 mb-4">
          {caracteristicas &&
            caracteristicas.map((caracteristica) => (
              <li key={caracteristica.idCaracteristica}>
                {caracteristica.nombre}: {caracteristica.valor}
              </li>
            ))}
        </ul>
        <button
            type="button"
            className={`btn btn-lg btn-block ${
                precio > 0 ? 'btn-info' : 'btn-light'
            }`}
            >
            {precio === 0 ? 'Comenzar gratis' : 'Comenzar'}
            </button>
      </div>
    </div>
  );
};

export default Box;
