import React from "react";
import Brand from "./shopBy/Brand";
import Category from "./shopBy/Category";
import Color from "./shopBy/Color";
import Price from "./shopBy/Price";

const ShopSideNav = ({lstiendas, marcas,categorias}) => {
  
  return (
    <div className="w-full flex flex-col gap-6">
      <Category categorias={categorias} icons={false} />
      <Color tiendas={lstiendas} />
      <Brand marcas={marcas}/>
      {/*<Price />*/}
    </div>
  );
};

export default ShopSideNav;
