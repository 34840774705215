import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  imagenAmortiguador,
  imagenFiltroAceite,
  imagenPastillasFreno,
  imagenBateriaAutomovil,
  imagenBujias,
  imagenFrenoDiscos,
  imagenCorreaDistribucion,
  imagenAceiteMotor,
  imagenFiltroAire,
  imagenRadiador,
  imagenBombaAgua,
  imagenSensorOxigeno,
  imagenTermostato,
  imagenSuspension,
  imagenKitEmbrague,
  imagenRadiadoract,
  imagenTurboCargador,
  imagenKitDistribucion,
  imagenValvulaEscape,
  dlimagenAmortiguador,
  imagenBombaDireccion,
  imagenMotorArranque,
  imagenAlternador,
  imagenFiltroCombustible,
  imagenBobinaEncendido,
  imagenSensorPresionNeumaticos,
  imagenKitEje,
  imagenValvulaEGR,
  imagenCorreaTransmision,
  imagenFiltroHabitaculo
} from "../assets/images/index";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Inicio",
    link: "/",
  },
  {
    _id: 1002,
    title: "Comprar",
    link: "/productos",
  },
  {
    _id: 1004,
    title: "Contacto",
    link: "contact",
  },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: 1001,
    img: imagenAmortiguador,
    productName: "Amortiguador Posterior RH",
    price: "361.00",
    color: "Tren de potencia / chasis",
    badge: true,
    des: "Nuestro repuesto original Amortiguador Posterior RH, Toyota, 4853142400 es para vehículos Toyota.",
  },
  {
    _id: 1002,
    img: imagenFiltroAceite,
    productName: "Filtro de Aceite",
    price: "15.00",
    color: "Negro",
    badge: true,
    des: "Filtro de aceite de alta calidad para mantener la pureza del aceite y prolongar la vida útil del motor.",
  },
  {
    _id: 1003,
    img: imagenPastillasFreno,
    productName: "Pastillas de Freno",
    price: "28.00",
    color: "Negro",
    badge: true,
    des: "Pastillas de freno de alto rendimiento para una frenada segura y eficiente.",
  },
  {
    _id: 1004,
    img: imagenBateriaAutomovil,
    productName: "Batería de Automóvil",
    price: "120.00",
    color: "Negro",
    badge: true,
    des: "Batería potente y duradera para garantizar un arranque confiable en todas las condiciones.",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: 1001,
    img: imagenAmortiguador,
    productName: "Amortiguador Posterior RH",
    price: "361.00",
    color: "Tren de potencia / chasis",
    badge: true,
    des: "Nuestro repuesto original Amortiguador Posterior RH, Toyota, 4853142400 es para vehículos Toyota.",
  },
  {
    _id: 1002,
    img: imagenFiltroAceite,
    productName: "Filtro de Aceite",
    price: "15.00",
    color: "Negro",
    badge: true,
    des: "Filtro de aceite de alta calidad para mantener la pureza del aceite y prolongar la vida útil del motor.",
  },
  {
    _id: 1003,
    img: imagenPastillasFreno,
    productName: "Pastillas de Freno",
    price: "28.00",
    color: "Negro",
    badge: true,
    des: "Pastillas de freno de alto rendimiento para una frenada segura y eficiente.",
  },
  {
    _id: 1004,
    img: imagenBateriaAutomovil,
    productName: "Batería de Automóvil",
    price: "120.00",
    color: "Negro",
    badge: true,
    des: "Batería potente y duradera para garantizar un arranque confiable en todas las condiciones.",
  },
  {
    _id: 1005,
    img: imagenBujias,
    productName: "Bujías",
    price: "7.00",
    color: "Plateado",
    badge: true,
    des: "Bujías de alto rendimiento para mejorar la eficiencia del motor y reducir las emisiones.",
  },
  {
    _id: 1006,
    img: imagenFrenoDiscos,
    productName: "Discos de Freno",
    price: "42.00",
    color: "Plateado",
    badge: true,
    des: "Discos de freno de calidad premium para un rendimiento de frenado óptimo y una mayor seguridad en la carretera.",
  },
  {
    _id: 1007,
    img: imagenCorreaDistribucion,
    productName: "Correa de Distribución",
    price: "18.00",
    color: "Negro",
    badge: true,
    des: "Correa de distribución de alta calidad para garantizar un sincronismo preciso del motor.",
  },
  {
    _id: 1008,
    img: imagenAceiteMotor,
    productName: "Aceite de Motor",
    price: "24.00",
    color: "Dorado",
    badge: true,
    des: "Aceite de motor sintético de primera calidad para una lubricación óptima del motor.",
  },
  {
    _id: 1009,
    img: imagenFiltroAire,
    productName: "Filtro de Aire",
    price: "9.00",
    color: "Blanco",
    badge: true,
    des: "Filtro de aire de alto flujo para mejorar el rendimiento del motor y la eficiencia de combustible.",
  },
  {
    _id: 1010,
    img: imagenRadiador,
    productName: "Radiador",
    price: "35.00",
    color: "Plateado",
    badge: true,
    des: "Radiador de refrigeración de calidad para evitar el sobrecalentamiento del motor.",
  },
  {
    _id: 1031,
    img: imagenBombaAgua,
    productName: "Bomba de Agua",
    price: "58.00",
    color: "Plateado",
    badge: true,
    des: "Bomba de agua de alta calidad para mantener el motor a una temperatura de funcionamiento óptima.",
  },
  {
    _id: 1032,
    img: imagenSensorOxigeno,
    productName: "Sensor de Oxígeno",
    price: "32.00",
    color: "Verde",
    badge: true,
    des: "Sensor de oxígeno de precisión para monitorear y optimizar la eficiencia del sistema de escape.",
  },
  {
    _id: 1033,
    img: imagenTermostato,
    productName: "Termostato",
    price: "21.00",
    color: "Plateado",
    badge: true,
    des: "Termostato de reemplazo de calidad para regular la temperatura del motor de manera eficiente.",
  },
  {
    _id: 1034,
    img: imagenSuspension,
    productName: "Kit de Suspensión",
    price: "180.00",
    color: "Negro",
    badge: true,
    des: "Kit de suspensión completo para mejorar el manejo, la estabilidad y la comodidad del vehículo.",
  },
  {
    _id: 1035,
    img: imagenKitEmbrague,
    productName: "Kit de Embrague",
    price: "240.00",
    color: "Negro",
    badge: true,
    des: "Kit de embrague de alta resistencia para una transmisión suave y confiable en todo momento.",
  },
  {
    _id: 1096,
    img: imagenRadiadoract,
    productName: "Radiador de Aceite",
    price: "85.00",
    color: "Plateado",
    badge: true,
    des: "Radiador de aceite de alto rendimiento para mantener una temperatura óptima del aceite lubricante del motor.",
  },
  {
    _id: 1097,
    img: imagenTurboCargador,
    productName: "Turbo Cargador",
    price: "620.00",
    color: "Negro",
    badge: true,
    des: "Turbo cargador de alta potencia para aumentar la potencia y el rendimiento del motor de manera significativa.",
  },
  {
    _id: 1098,
    img: imagenKitDistribucion,
    productName: "Kit de Distribución",
    price: "125.00",
    color: "Negro",
    badge: true,
    des: "Kit de distribución completo para mantener el sincronismo adecuado del motor y prevenir daños costosos.",
  },
  {
    _id: 1099,
    img: imagenValvulaEscape,
    productName: "Válvula de Escape",
    price: "38.00",
    color: "Plateado",
    badge: true,
    des: "Válvula de escape de alta calidad para regular el flujo de gases de escape y mejorar el rendimiento del motor.",
  },
  {
    _id: 1100,
    img: dlimagenAmortiguador,
    productName: "Amortiguador Delantero LH",
    price: "372.00",
    color: "Tren de potencia / chasis",
    badge: true,
    des: "Nuestro repuesto original Amortiguador Delantero LH, Ford, 4853142400 es para vehículos Ford.",
  },
  {
    _id: 1116,
    img: imagenBombaDireccion,
    productName: "Bomba de Dirección Hidráulica",
    price: "190.00",
    color: "Plateado",
    badge: true,
    des: "Bomba de dirección hidráulica de alta calidad para un control suave y preciso del sistema de dirección.",
  },
  {
    _id: 1117,
    img: imagenMotorArranque,
    productName: "Motor de Arranque",
    price: "150.00",
    color: "Negro",
    badge: true,
    des: "Motor de arranque de alto rendimiento para un arranque confiable y eficiente en una variedad de condiciones.",
  },
  {
    _id: 1118,
    img: imagenAlternador,
    productName: "Alternador",
    price: "200.00",
    color: "Plateado",
    badge: true,
    des: "Alternador de calidad premium para garantizar un suministro de energía confiable y constante en todo momento.",
  },
  {
    _id: 1119,
    img: imagenFiltroCombustible,
    productName: "Filtro de Combustible",
    price: "25.00",
    color: "Verde",
    badge: true,
    des: "Filtro de combustible de alta eficiencia para mantener la limpieza y el rendimiento óptimo del sistema de combustible.",
  },
  {
    _id: 1120,
    img: imagenBobinaEncendido,
    productName: "Bobina de Encendido",
    price: "45.00",
    color: "Negro",
    badge: true,
    des: "Bobina de encendido de alto rendimiento para una chispa potente y una combustión eficiente del combustible.",
  },
  {
    _id: 1131,
    img: imagenSensorPresionNeumaticos,
    productName: "Sensor de Presión de Neumáticos",
    price: "32.00",
    color: "Negro",
    badge: true,
    des: "Sensor de presión de neumáticos para monitorear y mantener la presión óptima de los neumáticos en tiempo real.",
  },
  {
    _id: 1132,
    img: imagenKitEje,
    productName: "Kit de Eje",
    price: "280.00",
    color: "Plateado",
    badge: true,
    des: "Kit de eje completo para garantizar una transmisión suave y un rendimiento óptimo del sistema de transmisión.",
  },
  {
    _id: 1133,
    img: imagenValvulaEGR,
    productName: "Válvula EGR",
    price: "90.00",
    color: "Plateado",
    badge: true,
    des: "Válvula EGR de alta calidad para controlar las emisiones y mejorar la eficiencia del motor.",
  },
  {
    _id: 1134,
    img: imagenCorreaTransmision,
    productName: "Correa de Transmisión",
    price: "58.00",
    color: "Negro",
    badge: true,
    des: "Correa de transmisión duradera para una transferencia eficiente de potencia dentro del sistema de transmisión.",
  },
  {
    _id: 1135,
    img: imagenFiltroHabitaculo,
    productName: "Filtro de Habitáculo",
    price: "20.00",
    color: "Blanco",
    badge: true,
    des: "Filtro de habitáculo de alta eficiencia para mantener el aire del habitáculo limpio y libre de contaminantes.",
  },
];
// =================== PaginationItems End here =================
