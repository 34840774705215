import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useServiceContext } from "../../hooks/useService";
import Box from "../../components/Box";
import { Grid } from '@mui/material';
import "bootstrap/dist/css/bootstrap.min.css";
import "./ofertas.css"
const Ofertas = () => {
  const location = useLocation();
  const {membresias, getMembresias}= useServiceContext();


  useEffect(()=>{
    getMembresias();
  },[]);
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
  //  setPrevLocation(location.state.data);
  }, [location]);

  const [clientName, setclientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");

  // ========== Error Messages Start here ============
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");
  // ========== Error Messages End here ==============
  const [successMsg, setSuccessMsg] = useState("");

  const handleName = (e) => {
    setclientName(e.target.value);
    setErrClientName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  const handlePost = (e) => {
    e.preventDefault();
    if (!clientName) {
      setErrClientName("Enter your Name");
    }
    if (!email) {
      setErrEmail("Enter your Email");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid Email");
      }
    }
    if (!messages) {
      setErrMessages("Enter your Messages");
    }
    if (clientName && email && EmailValidation(email) && messages) {
      setSuccessMsg(
        `Thank you dear ${clientName}, Your messages has been received successfully. Futher details will sent to you by your email at ${email}.`
      );
    }
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Contacto" prevLocation={prevLocation} />
      {successMsg ? (
        <p className="pb-20 w-96 font-medium text-green-500">er{successMsg}</p>
      ) : (
        <Grid container spacing={3} alignItems="center" alignContent={"center"} textAlign={"center"}>
          {membresias &&
            membresias.map((item, index) => (
              <Grid item key={index} xs={12} md={3} lg={4}  textAlign={"center"}>
                <Box
                  membresia={item.membresia}
                  caracteristicas={item.caracteristicas}
                  btnClass="tu-clase"
                  btnTitle="Tu Título"
                />
              </Grid>
            ))}
        </Grid>
      )}
    </div>
  );
};

export default Ofertas;
