import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  imagenAmortiguador ,
  imagenFiltroAceite ,
  imagenPastillasFreno ,
  imagenBateriaAutomovil ,
  noviable  ,
} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = ({producto,getsimilar,tagprod,productsim}) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
  <Heading heading="Los mas Nuevos" />
  <Slider {...settings}>
  {producto.map((product) => (
    <div className="px-2">
        <Product
          _id={product.idProducto.toString()}  // Convertir idProducto a cadena
          img={product.multimedia.length > 0 && product.multimedia[0].base64 ? `data:image/jpeg;base64, ${product.multimedia[0].base64}` : noviable}
          productName={product.codigo}
          badge={true}
          price={product.precioNormal.toFixed(2)}  // Formatear precioNormal a dos decimales
          des={product.descripcion}
        />
        </div>
      ))}
  </Slider>
</div>
  );
};

export default NewArrivals;
