import React, { useState ,useEffect} from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";
import { useServiceContext } from "../../hooks/useService";
import { useSearchContext } from "../../SearchContext";
const Shop = () => {
  const { listProductos,categorias,tiendas,getDataInicial,getListaProductos,getBusquedaProducto}= useServiceContext();
  const { searchQuery } = useSearchContext();
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [marcas, setMarcas] = useState([]);
  const [lcategorias, setLcategorias] = useState([]);
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };
  useEffect(()=>{
    getListaProductos();
    getDataInicial();
    let cat=categorias;
    setLcategorias(cat.filter(item => item.nivel !== "10"))
    let marcas=[];
    cat.map((dt)=>{
      console.log(dt);
      if(dt.nivel === "10"){//nivel definimos como marcas
        marcas.concat(dt.seccioneshijos);
        setMarcas(dt.seccioneshijos);
        console.log(dt.seccioneshijos);
      }
    }) 
  },[]);
  useEffect(() => {
    console.log('Valor de búsqueda en Shop:', searchQuery);
    let mbusqueda={};
    mbusqueda.buscador=searchQuery;
    getBusquedaProducto(mbusqueda);
  }, [searchQuery]);
  useEffect(()=>{
    let cat=categorias;
    setLcategorias(cat.filter(item => item.nivel !== "10"))
    let marcas=[];
    cat.map((dt)=>{
      console.log(dt);
      if(dt.nivel === "10"){//nivel definimos como marcas
        marcas.concat(dt.seccioneshijos);
        setMarcas(dt.seccioneshijos);
        console.log(dt.seccioneshijos);
      }
    }) 
  },[categorias]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Productos" />
      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 gap-10">
        <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <ShopSideNav lstiendas={tiendas} marcas={marcas} categorias={lcategorias}/>
          
        </div>
        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">
          <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} />
          <Pagination itemsPerPage={itemsPerPage} productos={listProductos}/>
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default Shop;
